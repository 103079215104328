var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"breadcrumbsItems":_vm.breadcrumbs,"actions":_vm.actions,"inactiveFilterValue":_vm.inactiveFilterValue,"showInactiveFilter":"","containerLarge":""},on:{"switch-inactive":_vm.switchInactive,"create-new":_vm.newTenant}},[_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tenants,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"item-class":_vm.itemRowStyle,"footer-props":_vm.$defaultDataTableFooterProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.app_instance_ids",fn:function(ref){
var item = ref.item;
return _vm._l((item.app_instance_ids),function(appInstance,index){return _c('span',{key:appInstance,attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.appInstanceTitleById(appInstance))),(index != item.app_instance_ids.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editTenant(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.toTenantAdmin(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-details ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tenant_admins')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.checkIsMaster())?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.toTenantQuotas(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-currency-usd ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tenant_quotas')))])])]}}])}),_c('DefaultModal',{ref:"modal",attrs:{"title":_vm.getModalTitle()},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DynamicFormContent',{attrs:{"fields":_vm.filteredFields}})]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }